<template>
	<div id="square" v-loading="loading"></div>
</template>

<script>
	import {
		getSign, //获取签名
		getEditSign,
		downloadImage, // 完成设计并下载
		uploadImage // 上传图片到服务器
	} from '@/request/apiStaging.js';
	import {
		UserInfo, // 获取用户信息 
	} from '@/request/apiPerCenter.js';
	import {
		saveOrUpdate //新增或修改网盘文件
	} from '@/request/apiStaging.js';
	import CktDesign from "@chuangkit/chuangkit-design"
	export default {
		name: 'square',
		components: {},
		data() {
			return {
				userInfo: '',
				sing: {
					// appId: "5d8c904dbab14b3b97aad359c7d47d1b", // 测试appid
					// key: '88F432988E1D18791DD6649F11B316BA', // 测试key
					// settingCode: "2WBP", // 测试标识
					appId: "b178a85e9fea4eccbf096381703e3a7d",
					key: '20E879C51314129FD5ED37ACBCBAC059',
					settingCode: "RL68",
					container: "#square",
					timestamp: '7C473C7AE5275480DF0B99C83D479A1D',
					sign: 1685518210000
				},
				loading: false
			}
		},
		created() {
			this.getsingfun();
			this.getUserInfo();
		},
		async mounted() {
			const design = CktDesign.createTemplateCenter(this.sing);
			await design.open();
			design.onChooseTemplate((templateItem) => {
				// 调起编辑器
				this.getEditSignFun(templateItem);
			})
			this.downloadImageFun();
		},
		methods: {
			// 监听下载
			downloadImageFun() {
				let that = this;
				window.chuangkitComplete = function(data) {
					console.log(JSON.parse(JSON.stringify(data)));

					// 将完成的设计图上传到网盘

					data['source-urls'].map(item => {
						console.log(item)
						fetch(item).then(res => res.blob()).then(blob => {
							const a = document.createElement('a');
							document.body.appendChild(a)
							a.style.display = 'none'
							// 使用获取到的blob对象创建的url
							const url = window.URL.createObjectURL(blob);
							a.href = url;
							// 指定下载的文件名
							a.download = `${data['design-name']}`;
							a.click();
							document.body.removeChild(a)
							// 移除blob对象的url
							window.URL.revokeObjectURL(url);
						});

						uploadImage({
							type: 'spider',
							urlStr: item
						}).then((res) => {
							console.log(res)
							saveOrUpdate({
								files: [{
									imagePath: res.data,
									raw: "2",
									title: data['design-name'],
									tagId: '2'
								}],
								mechanismId: 101
							})
						})
					})
				}
			},
			getsingfun() {
				let data = {
					settingCode: this.sing.settingCode,
					appId: this.sing.appId,
					key: this.sing.key,
				}
				
				getSign(data).then(res => {
					this.sing.timestamp = res.timestamp;
					this.sing.sign = res.sign;
				})
			},

			getEditSignFun(item) {
				let data = {
					userFlag: this.userInfo.userId,
					appId: this.sing.appId,
					key: this.sing.key,
					settingCode: this.sing.settingCode,
				}
				
				getEditSign(data).then(res => {
					var option = {
						app_id: this.sing.appId,
						expire_time: res.timestamp,
						sign: res.sign,
						user_flag: this.userInfo.userId,
						template_id: item.templateId,
						coop_material_limit: 2,
						coop_font_limit: 2,
						charging_template_limit: 2,
						device_type: 1,
						version: '2.0',
						enable_authorize: 1,
						taxpayer_name: '生命绿洲有限公司',
						taxpayer_phone: "021-51857612",
						taxpayer_number: '91429004MA49527MXF'
					}
					
					const cktDesign = new CktDesign(option)
					cktDesign.open()
				})
			},

			// 获取用户信息
			getUserInfo() {
				UserInfo().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data.user;
					}
				}).catch(err => {
					console.log(err)
				})
			},

		}
	}
</script>

<style lang="less" scoped>
	#square {
		width: 100%;
		height: calc(100% - 100px);
	}
</style>