import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 机构列表查询 /spider/web/mechanism/list
export function mechanismList(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/deptTreeList",
		method: "get",
		data: params
	});
}

// 获取服务类型列表
export function tagTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/tag/treeList",
		method: "get",
		params: params
	});
}

// 获取项目列表
export function itemList(params) {
	return request({
		url: baseUrl + "/spider/web/item/getMyItem",
		method: "get",
		params: params
	});
}

// 新增或修改网盘文件 
export function saveOrUpdate(params) {
	return request({
		url: baseUrl + "/spider/web/fileNetdisk/saveOrUpdate",
		method: "post",
		data: params
	});
}

// h获取创可贴签名和时间戳
export function getSign(params) {
	return request({
		url: baseUrl + "/spider/sign/getSign",
		method: "get",
		params: params
	});
}

export function getEditSign(params) {
	return request({
		url: baseUrl + "/spider/sign/getEditSign",
		method: "get",
		params: params
	});
}

export function downloadImage(params) {
	return request({
		url: baseUrl + "/spider/sign/downloadImage",
		method: "get",
		params: params
	});
}

export function uploadImage(params) {
	return request({
		url: baseUrl + "/spider/web/fileNetdisk/uploadImageOfSave",
		method: "post",
		data: params
	});
}